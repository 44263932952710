import Cookies from 'js-cookie';
export default function () {

    const bar = document.querySelector('.below-header-bar');

    if (bar) {
        const closeButton = bar.querySelector('.bar-close');
        closeButton.addEventListener('click', function () {
            bar.remove();
            Cookies.set('cookie_cookies_below_header_bar', '1', { expires: 1 });
        });
    }
}