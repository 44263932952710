import { Pagination, Navigation } from 'swiper/modules';

export default function () {
    window.addEventListener('load', () => {
        const anySwiper = document.querySelector('.swiper:not(.product-images)');

        if (!anySwiper) return;

        import('swiper').then((module) => {
            const Swiper = module.default;
            const cs = getComputedStyle(document.documentElement);

            //MAIN PAGE PRODUCTS
            const breakpoints = {
                1: {},
                375: {},
                601: {},
                1100: {},
                1280: {}
            };

            const slider1 = new Swiper('.promo-new-wrapper .swiper', {
                modules: [Navigation],
                preloadImages: false,
                navigation: {
                    nextEl: '.swiper-button-next.slider1-button',
                    prevEl: '.swiper-button-prev.slider1-button'
                },
                loop: false,
                breakpoints: breakpoints,
                slidesPerView: +cs.getPropertyValue('--prodSwiperCols'),
                spaceBetween: +cs.getPropertyValue('--prodGap').replace('px', '')
            });

            slider1.on('breakpoint', function () {
                slider1.params.slidesPerView = +cs.getPropertyValue('--prodSwiperCols');
                slider1.params.spaceBetween = +cs.getPropertyValue('--prodGap').replace('px', '');
                slider1.update();
            });

        });
    }, false);
}